$( document ).ready(function() {
    var searchButton = $('.search-wrap button.btn-primary');
    var searchInput = $('.search-wrap input[name=search]');

    /**
     * Make search button react to state of input field
     */
    var setButtonState = function(){
        if (searchInput.val().length >= 3) {
            searchButton.removeAttr('disabled');
        } else {
            searchButton.attr('disabled', 'disabled');
        }
    };

    searchInput.on('keyup keydown blur focus', setButtonState);
    setButtonState();
});
